// This is the root SCSS file. All styles should be imported here.

@import "bootstrap";

html {
  background-color: #ffffff;
}

body {
  line-height: 1.35em !important; /* override bootstrap */
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif !important;
}

p {
  max-width: 40em; /* avoid too long lines to make text more readable */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Exo 2", sans-serif !important;
  text-transform: uppercase !important;
}

.menu {
  background-color: #4ea5d9;
  margin-left: -15px;
  margin-right: -15px;
}

.menu-item,
.submenu-item {
  /*margin-left: -15px;*/
  font-family: "Exo 2", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  /*text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);*/
  text-align: center;
  font-size: 20px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.submenu {
  transition: max-height 500ms;
  max-height: 0;
  overflow: hidden;
}

.menu-item:hover + .submenu,
.submenu:hover {
  max-height: 150px;
}

.submenu-item {
  font-size: 14px;
}

.menu-item.active,
.submenu-item.active {
  font-weight: 600;
}

.menu-link,
.submenu-link {
  display: block;
  padding: 1rem;
  color: white;
}

.menu-link:hover,
.submenu-link:hover {
  text-decoration: none;
  color: white;
  font-weight: 600;
  /*text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);*/
}

.submenu-link {
  padding-left: 1.75rem;
}

.answer-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.answer-buttons button + button {
  margin-left: 1rem;
}

.btn-group {
  & > {
    .btn-primary,
    .btn-outline-primary,
    .btn-secondary,
    .btn-outline-secondary,
    .btn-success,
    .btn-outline-success,
    .btn-danger,
    .btn-outline-danger {
      font-weight: 600;
    }
    .btn-primary,
    .btn-secondary,
    .btn-success,
    .btn-danger {
      border-color: #fff;
      text-shadow: 0 0 7px rgba(0, 0, 0, 0.7);
    }
  }
}
.btn-group > .btn-primary:active {
  background-color: #0275d8;
}

/* C♯/D♭  D♯/E♭ */
.btn-group--sharps-and-flats-1 {
  margin-left: 2rem;
}

/* F♯/G♭  G♯/A♭  A♯/B♭ */
.btn-group--sharps-and-flats-2 {
  margin-left: 4.2rem;
}

.btn-group--sharps-and-flats.btn-group-lg > .btn {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.memo-question {
  text-align: center;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.fretboard-container {
  margin-bottom: 25px;
  margin-left: auto; /* center the fretboard image */
  margin-right: auto;
  max-width: 800px;
}

.tips-title {
  margin-bottom: 1em;
}

.btn,
.btn-group-lg > .btn {
  border-radius: 1.5rem;
}

// FirebaseUI sign in buttons
.firebaseui-idp-button.mdl-button {
  border-radius: 1.5rem;
}
